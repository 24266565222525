import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ImprintPage = () => (
  <Layout>
    <Seo title="Impronta" />

    <section className="py-10 md:py-14 lg:py-24">
      <div className="px-4 mx-auto">
        <div className="max-w-4xl mx-auto">
          <div className="px-4 mb-12 lg:mb-0 text-center">
            <h1 className="text-4xl lg:text-5xl font-heading mb-10">
              Impronta
            </h1>
            <p className=" mb-5">
              <strong>Montebello Group S.r.l.s.</strong>
              <br />
              Codice Fiscale: IT12191120968
            </p>
            <p className=" mb-5">
              <dl>
                <dt className="font-semibold">Indirizzo</dt>
                <dd className="mb-2">
                  Via P. Maroncelli, 36 <br />
                  2038 Sergno (MB), Italia
                </dd>

                <dt className="font-semibold">Email</dt>
                <dd className="mb-2">
                  <a
                    href="mailto:info@gruppomontebello.it"
                    className="text-blue-600 hover:text-blue-500"
                  >
                    info@gruppomontebello.it
                  </a>
                </dd>

                {/*
                  <dt className="font-semibold">Autorità di vigilanza</dt>
                  <dd className="mb-2">TBD</dd>
                */}
              </dl>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ImprintPage
